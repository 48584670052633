










































































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
//  types
import { CellData, DataQualityHeader, DataQualityRow } from "@/types/dataQuality/dataQualityTable";
//  components
import DataCell from "./DataCell.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/clients/User.module";
import outageHubConnection from "../../../utilities/signalr/signalr-outagehub-service";

const User = getModule(UserModule, store);

@Component({
  components: {
    DataCell,
  },
})
export default class DataAvailabilityTable extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: Boolean;
  @Prop({ type: Array, default: () => [] }) headers!: DataQualityHeader[];
  @Prop({ type: Array, default: () => [] }) rows!: DataQualityRow[];
  @Prop({ type: Array, default: () => [] }) links!: string[] | null;

  limitPerPage = 10;

  //  @Getters

  get isLessThanLimitPerPage(): boolean {
    return this.rows.length <= this.limitPerPage;
  }

  get indicatorsCount(): number {
    return this.headers.length;
  }

  get skeletonStructure(): object {
    return { "table-row": `table-cell@6` };
  }

  //  @Methods
  customSort(items: any, sortBy: any, isDesc: any): [] {
    //  do nothing when the sorting is reset
    if (!sortBy[0]) return items;

    //  sort as strings
    const result = items.sort((a: any, b: any) => {
      if (isDesc[0]) return b[sortBy].value < a[sortBy].value ? -1 : 1;
      return a[sortBy].value < b[sortBy].value ? -1 : 1;
    });
    return result;
  }

  cellData(row: DataQualityRow, header: DataQualityHeader, index: number): any {
    return {
      column: header.value,
      columnName: header.text,
      type: header.type,
      value: row[header.value].value,
      link: row[header.value].link,
      align: header.align,
      lastDataTimestamp: row[header.value].lastDataTimestamp,
      outageStartTime: row[header.value].outageStartTime,
      outageDetectionTime: row[header.value].outageDetectionTime,
    };
  }

  //  @Hooks
  created() {
    if (outageHubConnection.state === "Disconnected") {
      outageHubConnection
        .start()
        .then(() => {
          outageHubConnection.invoke("JoinGroup", `company-${User.userCompanyId}`);
        })
        .catch(err => console.error(err.toString()));
    } else {
      console.log("Connection is already started or in the process of starting.");
    }

    outageHubConnection.on("OutageUpdated", (outage, vesselInfo) => {
      this.$emit("outage-updated", vesselInfo.vesselName, outage.featureName, outage);
    });
  }

  beforeDestroy() {
    outageHubConnection
      .invoke("LeaveGroup", `company-${User.userCompanyId}`)
      .then(() => {
        outageHubConnection.off("OutageUpdated");
        outageHubConnection.stop();
      })
      .catch(err => console.error(err.toString()));
  }
}
